import RoleBasedComponent from "components/common/RoleBasedComponent/RoleBasedComponent";
import { Nav, Ul, NavLink2 } from "./styles";
import { Role } from "../users/types";

export default function Navigation() {
  return (
    <Nav>
      <Ul>
        <RoleBasedComponent
          require={[
            Role.ADMIN,
            Role.FIELD_OPERATOR,
            Role.INTERNAL_SUPPORT,
            Role.CUSTOMER_ADMIN,
            Role.SUPPORT_ADMIN,
          ]}
        >
          <NavLink2 to={"/baselines"}>Baselines</NavLink2>
        </RoleBasedComponent>
        <NavLink2 to={"/building-model"}>Building Model</NavLink2>
        <RoleBasedComponent require={[Role.ADMIN]}>
          <NavLink2 to={"/ingest"}>Ingest</NavLink2>
        </RoleBasedComponent>
        <RoleBasedComponent require={[Role.ADMIN, Role.INTERNAL_SUPPORT]}>
          <NavLink2 to={"/integrations"}>Integrations</NavLink2>
        </RoleBasedComponent>
        <NavLink2 to={"/measures"}>Measures</NavLink2>
        <RoleBasedComponent require={[Role.ADMIN]}>
          <NavLink2 to={"/sources"}>Sources</NavLink2>
        </RoleBasedComponent>
        <NavLink2 to={"/users"}>Users</NavLink2>
      </Ul>
    </Nav>
  );
}
