export enum IngestEvents {
    LOOkUP_INGEST_POINT = "LookupIngestPoint",
    LOOKUP_ODAS = "LookupODASInformation",
    LOOKUP_S2 = "LookupS2",
    REGISTER_ODAS = "RegisterODAS",
    REGISTER_ODAS_INGEST_POINT = "RegisterODASIngestPoint",
    REGISTER_ODAS_INGEST_POINT_CSV = "RegisterODASIngestPointCSV",
    REGISTER_S2 = "RegisterS2",
    SET_ODAS_PASSWORD = "RegisterODASPassword",
    UPDATE_INGEST_POINT = "UpdateIngestPoint",
    REGISTER_SMT_INGEST_POINT = "RegisterSMTIngestPoint",
    REGISTER_URJANET_INGEST_POINT = "RegisterUrjanetIngestPoint",
    REGISTER_URJANET_INGEST_POINT_CSV = "RegisterUrjanetIngestPointCSV",
    REGISTER_HTTP_INGEST_POINT = "RegisterHTTPIngestPoint",
    REGISTER_HTTP_INGEST_POINT_CSV = "RegisterHTTPIngestPointCSV",
    REGISTER_JACE_INGEST_POINT = "RegisterJACEIngestPoint",
    REGISTER_JACE_INGEST_POINT_CSV = "RegisterJACEIngestPointCSV"
}